.App {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #0B164C;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: absolute;
  background-color: #FFF;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 30px;
  z-index: 9999;
}

.App-header-logo {
  height: 60px; padding-right: 30px; display: block;
}

.bnaLogo  {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 40px;
  top: 10px;
}

.bnaLogo img { height: 60px;}

.App-header-logo img { height: 60px;}

.map {
  position: absolute;
  width: 100%;
  height: calc(100% - 65px);
  top: 65px;
}

.statZone {
  position: absolute;
  width: 500px;
  height: 100%;
  z-index: 9998;
  background: linear-gradient(to right, #15305d 0%, rgba(21,48,93,.8) 60%,rgba(21,48,93,0) 100%);
  box-sizing: border-box;
  padding: 30px;
  padding-top: 120px;
}

.statBlock {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
} 

.statLabel {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 13px;
  color: #6BBEE4;
}

.statplusDet {
  width: 140px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 13px;
  color: #FFF;
  background: #E51788;
  line-height: 18px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
}

.blocKm {
  width: 280px;
  font-size: 50px;
  font-family: 'Montserrat';
  font-weight: 800;
  color: #FFF;
}

.blocOver {
  width: 280px;
  font-size: 36px;
  font-family: 'Montserrat';
  font-weight: 800;
  color: #FFF;
}

.blocKm span {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
}

.blocOver span {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
}

.blackList {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 30px;
} 

.colRed {
  color: #E51788;
}

.classementZone {
  position: absolute;
  width: 500px;
  height: 100%;
  z-index: 9998;
  background: linear-gradient(to right,rgba(21,48,93,0) 0%,rgba(21,48,93,.8) 60%,  #15305d 100%);
  box-sizing: border-box;
  padding: 30px;
  padding-top: 120px;
  right: 0;
}

.classItems {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 180px);
  bottom: 0;
}

.clItems {
  position: relative;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding-right: 20px;
}

.classItem {
  position: relative;
  width : 100%;
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 10px;  
  border-top-left-radius : 10px;
  background: rgba(5,13,51,0.6);
  border : 1px rgba(6,69,201,.3) solid;
  overflow: hidden;
  box-sizing: border-box;
}

.clNumber {
  width: 80px;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size : 50px;
  color : #FFF;
  overflow: hidden;
  float: left;
  line-height: 50px;
  text-align: center;
}

.clContent {
  width: calc(100% - 80px);
  align-items: center;
  justify-content: flex-start;
  padding-left: 14px;
  border-left: 2px solid rgba(6,69,201,.3);
  float: left;
  box-sizing: border-box;
}

.clContentName {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size : 16px;
  color : #FFF;
  width: 100%;
  overflow: hidden;
}

.clStats {
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
}

.clStatsLabel
{
  background: rgba(6,69,201,.3);
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  color: #FFF;
  font-family: 'Montserrat';
  font-size : 10px;
  border-radius: 6px;
  margin-right: 5px;
  float: left;
  line-height: 20px;
}

.clStatsValue
{
  height: 20px;
  color: #FFF;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size : 11px;
  margin-right: 10px;
  float: left;
  line-height: 20px;
}


.leaflet-container .leaflet-tile-pane img {
	filter: grayscale(50%) brightness(40%) sepia(100%) hue-rotate(-180deg) saturate(120%) contrast(200%);

}

.overley {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(21,48,93,.8);
  top: 0;
  left: 0;
  z-index: 999999;
}

.popUp {
  position: absolute;
  width: 640px;
  height: 420px;
  border-radius: 10px;
  background: #FFF;
  top: 50%;
  left: 50%;
  margin-left: -320px;
  margin-top: -210px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
}

.popUpHeader {
  position: relative;
  width: 100%;
  height: 80px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  padding : 15px
}

.popUpTitle {
  font-size: 'Montserrat';
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  height: 30px;
}

.popUpTitle span {
  font-size: 'Montserrat';
  font-size: 14px;
  font-weight: 800;
}

.popUpSubTitle {
  font-size: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.popUpContent {
  position: relative;
  width: 100%;
  height: 340px;
}

.popUpContentItems {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-right: 20px;
  display: inline-block;
}

.popUpContentItem {
  width: 100%;
  border-bottom: 1px solid #CCC;
  padding: 5px 0;
  box-sizing: border-box;
  display: inline-block;
}

.popUpContentItem > .itemName
{
  font-size: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  float: left;
  padding-right: 20px;
}

.popUpContentItem > .itemOver
{
  font-size: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  float: left;
  padding-right: 20px;
}

.popUpContentItem > .alRight {
  float: right;
  padding-right: 0;
  background: red;
  color: #FFF;
  padding: 0 10px;
  border-radius: 6px;
  line-height: 20px;
}

.closePoPup {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: 20px;
  background: #CCC;
  border-radius: 20px;
  font-size: 'Montserrat';
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.participNumber {
  color: #FFF;
  font-size: 'Montserrat';
  font-size: 10px;
  font-weight: 700;
  margin-top: 4px;
}